
:root {
    --theme-color: #d62929;
    --logo-color: #db2d2d;
    --user-tab-color: #d32626;
    --user-tab-border: #fff1f1;
    --border-color: #c01616;
    --nav-text: #ffebeb;
    --nav-text-hover: #ff4848;
    --report-background: #f4faff;
    --report-gradiant: #e3f2ff;
    --nav-border: #dd2d2d;
    --shadow-middle-color: #ffa4a4;
    --shadow-last-color: #ff4747;
    --marquee-shade-color: #b41a1a;
    --profile-variant-color: #f8fcff;
    --sidebar-border-color: #ec0000;
    --sidebar-menu-text: #003f8c;
    --sidebar-dark-gradian: #aa0000;
    --score-card-color: #800002;
    --score-dark-color: #720002;
    --theme-button: #003f8c;
    --font-family-monospace: "Noto Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans" !important
}