/*themes css*/
/*theme default color code: #fec726*/
/*colors theme css*/
.melr span,
span.pro-user-name,
#sidebar-menu>ul>li>a:focus,
#sidebar-menu>ul>li>a:hover,
.nav-second-level li a:focus,
.nav-second-level li a:hover,
.horse-status span b,
.matchname a:hover,
.dBack,
.dBack:hover,
.tv-icon:hover,
.sb-icon:hover,
.bookmark-icon .mdi-bookmark,
.marketTeamName .bookmark-icon .mdi-bookmark,
a.collapseDown,
.book,
.clientUi h2 a{
	color: #fec726 !important;
}
.topnav,
.dtui ul li a.active,
.dtui ul li a:hover,
.dtui ul li a:focus,
.accordion .card-header,
.btn-place,
.betslip .input-group .input-group-text,
.formSearchbar .btn-gs,
.filterIcon,
.filterIcon:hover,
.clientUi .nav-pills a.active,
.clientUi .nav-pills a:hover,
.ch-form .btn-submit,
.jackpotList:hover,
.cc-detail .btn-group .btn-plcbet,
.jack_row_th .jack_row_column,
.jack-bets{
	background: #fec726 !important;
}
.detailsTabsDesign ul li a.active,
.detailsTabsDesign ul li a:hover{
	box-shadow: inset 0px 2px 0px 0px #fec726;
}
.modalCustomDesign .nav li a.active,
.modalCustomDesign .nav li a:hover{
	border-bottom: 3px solid #fec726 !important;
}
.clientUi .user-profile {
    background: -webkit-gradient(to right, #fec726, #f5d475);
    background: linear-gradient(to right, #fec726, #f5d475);
	align-items: center;
	display: flex;
	justify-content: center;
}
.betslip .input-group,
.betslipDesigning{
	border:1px solid #fec726;
}
/*colors theme css*/
body{
	background: #fff;
}
body,
a,
b,
small,
span,
h1,h2,h3,h4,h5,h6,
div{
	font-family: Arial,sans-serif;
}
iframe{
	width: 100%;
}
.profit{
	color: green !important;
}
.loss{
	color: red !important;
}
.profit2{
	color: #06cd06 !important;
}
.win{
	background: green !important;
	color: #fff !important;
	font-weight: 800;
}
.lose{
	background: red !important;
	color: #fff !important;
	font-weight: 800; 
}
body[data-sidebar-size=condensed]:not([data-layout=compact]){
	min-height: auto;
}
body[data-sidebar-size=condensed] .logo-box{
	width: 200px !important;
}
body[data-sidebar-size=condensed] .mdi-menu::before,
.sidebar-enable .mdi-menu::before{
    content: "\f0159";
}
body[data-sidebar-size=condensed] .left-side-menu{
	width: 0 !important;
	z-index: 0;
}
body[data-sidebar-size=condensed] .left-side-menu #sidebar-menu>ul>li>a{display: none;}
body[data-sidebar-size=condensed] .content-page,
body[data-sidebar-size=condensed] .topnav,
body[data-sidebar-size=condensed] .commentary{
	margin-left: 0 !important;
}
.melr a,
.nav-user{
	padding: 0 3px !important;
}
.navbar-custom .button-menu-mobile {
    color: #646363;
    height: 60px;
    line-height: 60px;
}
.navbar-custom .topnav-menu .nav-link{
	line-height: 60px;
	max-height: 60px;
}
.melr span,
span.pro-user-name{
	background: #404040;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #7F7F7F;
	border-radius: 4px;
	/*color: #fec726;*/
	padding: 4px;
	text-transform: capitalize;
	font-size: 12px;
}
.topbar-dropdown .melr{
	display: none !important;
}
.logout,
.logout:hover{
	background: #404040 !important;
}
.dropdown-menu.profile-dropdown.show{
	padding: 0;
}
.profile-dropdown {
    background: #111111;
    border: none;
    box-shadow: none;
    border-radius: 0;
}
.profile-dropdown a{
	color: #c3c2c2;
	text-transform: capitalize;
	font-size: 12px;
	padding: 4px 12px !important;
}
.profile-dropdown a:hover{
    background: #151515;
    color: #fff;
}
.logo-box{
	display: flex;
	width: 200px;
	height: 60px;
}
.navbar-custom .app-search .app-search-box input{
	border-top-left-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}
.navbar-custom .app-search .app-search-box .input-group-text{
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
}
.navbar-custom .app-search .form-control,
.navbar-custom .app-search .btn{
	background: #fff;
	height: 25px;
	color: #000;
}
.navbar-custom .app-search .btn{
	color: #000;
}
.navbar-custom .app-search .form-control::placeholder{
	color: #aaa !important;
}
.navbar-custom .app-search .btn{
	padding: 2px 8px;
}
.content-page{
	background: #eee;
    margin-left: 200px;
    margin-top: 123px;
    padding: 0 2px 2px 2px;
}
.topnav-menu .dropdown-menu {
    background: #151515;
    border-color: #050505;
}
.topnav-menu .dropdown-menu a{
    padding: 5px 10px;
    color: #fff;
    font-size: 12px;
}
.topnav-menu .dropdown-menu a:hover{
	background: #050505;
}
.topnav{
	margin-left: 200px;
	/*background: #fec726;*/
	height: auto;
	padding: 0;
}
.footer{
	left: 200px;
}
.topnav .container-fluid{
	padding: 0;
}
.navbar-custom{
	height: 70px;
	background: transparent linear-gradient(0deg, #010101 0%, #404040 100%);

}
.left-side-menu{
	padding-top: 0;
	width: 200px;
	background: #050505;
}
.topnav .navbar-nav .nav-link{
	text-transform: uppercase;
    padding: 3px 10px !important;
    color: #000;
    font-weight: 400;
    font-size: 12px;
    border-right: 1px solid #111111;
}
.topnav .navbar-nav .nav-item:last-child .nav-link{
	border-right: none;
}
.topnav .navbar-nav .nav-link.active,
.topnav .navbar-nav .nav-link:hover{
	color: #fff;
	background: transparent linear-gradient(0deg, #777777 0%, #2C2C2C 100%) 0% 0% no-repeat padding-box;
}
.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover,
.topnav .dropdown:hover>.nav-link{
	color: #fff !important;
}
#sidebar-menu>ul>li>a{
	padding: 6px 15px;
	color: #c3c2c2;
	text-transform: capitalize;
	font-size: 12px;
	font-weight: 100;
	background: #39393952;
	margin-bottom: 1px;
}
#sidebar-menu>ul>li>a:focus,
#sidebar-menu>ul>li>a:hover{
/*	color: #fec726 !important;*/
}
#sidebar-menu>ul>li>a i {
    margin: 0 15px 0 3px;
    color: #646363;
}
.nav-second-level li a{
	color: #a7a7a7;
	padding: 4px 20px;
}
.nav-second-level li a:focus,
.nav-second-level li a:hover {
    /*color: #fec726;*/
}
#sidebar-menu .menu-title {
    font-size: 12px;
    color: #fff;
}
.horse_match{
	background: #fff;
}
.horse_match .horse-list b{
	font-weight: 500;
}
.horse_match .horse-list {
    background: #fff;
    padding: 10px;
    margin: 5px;
    border-radius: 3px;
    display: block;
    text-align: center;
    color: #000;
    box-shadow: 0 1px 4px 0px #ddd;
}
.horse_match .horse-list:hover{
	background: #eee;
	box-shadow: 0 0 3px 1px #ddd;
}
.horse_match .horse-list span{
	display: block;
	font-size: 12px;
}
.horse-status{
	justify-content: space-between;
	display: inline-flex;
	width: 100%;
	line-height: 2;
	padding: 0 5px;
	background: #3b3b3b;
}
.horse-status span{
	color: #fff;
	font-size: 12px;
	text-transform: capitalize;
}
.horse-status span b{
	/*color: #fec726;*/
	font-weight: 500;
}
.commentary {
    margin-top: 98px;
    padding: 0 calc(24px * .5);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
    background: #282828;
    margin-left: 200px;
    height: auto;
    display: flex;
    line-height: 25px;
    color: #fff;
    font-size: 12px;
    text-transform: capitalize;
}
.dtui{
	background: none;
	border: none;
	box-shadow: none;
}
.dtui .card-body{
	padding: 0;
}
.dtui ul{
	border-radius: 100px;
	margin: 0 auto;
	border: 1px solid #eee;
	/* width: 50%; */
	background: #fff;
	padding: 40px 0;
	justify-content: center;
	background: url(https://wallpapercave.com/wp/wp8054782.jpg);
	background-size: cover;
}
.dtui ul li a{
    border-radius: 100px !important;
    color: #fff !important;
    font-size: 12px;
    border: 1px solid #fff !important;
    padding: 5px 30px;
    text-transform: capitalize;
}
.dtui ul li a.active,
.dtui ul li a:hover,
.dtui ul li a:focus{
/*	background: #fec726 !important;*/
}
.dtui .tab-content{
	padding: 0;
	margin: 0;
}
.custom-accordion i{
	position: absolute;
	right: 0;
	font-size: 12px;
}
.custom-accordion a.collapsed i.accordion-arrow:before {
    content: "\f0417";
}
.custom-accordion a[aria-expanded="true"] .mdi-custom-icon::before {
    content: "\f0374" !important;
}
.custom-accordion a[aria-expanded="false"] .mdi-custom-icon::before {
    content: "\f0415" !important;
}
.accordion .card-header{
	/*background: #fec726;*/
	padding:6px 8px;
	border-radius: 0 !important;
}
.accordion .card-header h5{
	text-transform: uppercase;
	font-size: 12px;
	color: #000;
}
.accordion .card{
	margin-bottom: 1px !important;
	background: none;
	box-shadow: none;
}
.tableeventlist div{
	font-size: 12px;
	color: #101010;
	text-transform: capitalize;
	border: 1px solid #d9d9d9;
	border-top: none;
	border-right: none;
	align-items: center;
	height: 42px;
	display: flex;
}
.tableeventlist .row:first-child div{
/*	border-top: 1px solid #d9d9d9 !important;	*/
}
.tableeventlist .row:last-child div{
	border-bottom: 1px solid #d9d9d9;
}
.tableeventlist .row .col-md-4:last-child{
	border-right: 1px solid #d9d9d9 !important;	
}
.tableeventlist .row .col-md-1.col-2:first-child{
	border-left: none;
}
.tableeventlist p{
	margin: 0 auto;
	padding: 0;
	font-weight: 500;
}
.tableeventlist .row{
	transition: 0.9s;
}
.tableeventlist .row:nth-child(odd){background: #fff;}
.tableeventlist .row:nth-child(even){background: #f8f8f8;}
.tableeventlist .row:hover{background: #fff;}
.matchname{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.matchname a{
	transition: 0.9s;
	color: #000 !important;
}
.matchname a:hover{
/*	color: #fec726 !important;*/
}
.lfbm{
	font-size: 12px;
}
.live{
	background: red;
	padding: 1px 4px;
	color: #fff;
	border-radius: 2px;
}
.fbm{
	font-family: sans-serif;
	font-weight: 600;
	text-transform: uppercase;
}
.inplay{
	font-size: 12px;
	text-transform: capitalize;
    width: auto;
    display: block;
    margin: 0 auto;
	letter-spacing: 0;
	background: url('../img/inplay-bg.png') repeat-y;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: aitf 80s linear infinite;
	-webkit-transform: translate3d(0,0,0);
	-webkit-backface-visibility: hidden;
	font-weight: 500 !important;
}
@-webkit-keyframes aitf {
	0% { background-position: 0% 50%; }
	100% { background-position: 100% 50%; }
}
.league{
	color: #aaa;
	display: block;
	font-size: 12px;
	font-weight: 400;
}
.listOddsStyle{
	width: 100%;
	display: flex;
	justify-content: center;
}
.listOddsStyle .btn-group{
	width: 32%;
	margin: 0 auto;
}
.listOddsStyle .col-md-1,
.listOddsStyle .col-md-2,
.listOddsStyle .col-md-3,
.listOddsStyle .col-md-4{}
.listOddsStyle .btn-group button{
	width: 50%;
	border: none;
	height: 30px;
}
.rightSidebar{
	width: 21%;
	padding: 0;
	/*box-shadow: -2px -1px 6px rgb(0 0 0 / 18%);*/
	position: fixed;
	overflow-y: auto;
	overflow-x: hidden;
	top: 123px;
	bottom: 0;
	right: 1px;
	background: #d9d9d9;
}
body[data-sidebar-size=condensed] .rightSidebar {
    max-width: 100%;
    min-width: 24.7%;
    width: 21%;
}
.detailContentSection {
    /*overflow-y: auto;
    height: 100vh;
    overflow-x: hidden;*/
}
.detailPage .col-xl-9{
	padding: 0;
}
.eventtitle{
	margin: 0 auto;
	justify-content: center;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	display: flex;
	align-items: center;
	vertical-align: middle;
	padding: 5px 0;
	padding-left: 5px;
	background: #fff;
	text-align: center;
}
.dBack,
.dBack:hover {
    position: absolute;
    left: 5px;
    /*color: #fec726;*/
    font-weight: 900;
}
.sbtvIcons{
	position: absolute;
	right: 5px;
}
.sb-icon {
    border-right: 1px solid #282828;
}
.tv-icon{
	padding-left: 3px;
}
.tv-icon,
.sb-icon{
	color: #000;
	font-size: 20px;
}
.tv-icon:hover,
.sb-icon:hover{
	/*color: #fec726;*/
}
.DetailsOddsDiv{
	background: #f9f9f9;
}
.odds_head{
	background: #282828;
	align-items: center;
}
.odds_head .btn-group{
	margin: 0 auto;
	text-align: center;
	display: table;
}
.DetailsOddsDiv .row{
	margin: 0;
}
.odds_body{
	align-items: center;
}
.DetailsOddsDiv .odds_body{
	border-bottom: 1px solid #eee;
}
.DetailsOddsDiv .odds_body:last-child{
	border-bottom: none;
}
.DetailsOddsDiv .row .col-md-6{
	padding: 0;
}
.marketName{
	width: 100%;
	margin: 0;
/*	margin-left: 5px;*/
	text-transform: uppercase;
	font-size: 12px;
	color: #fff;
	position: relative;
	display: inline-flex;
	align-items: center;
}
.info-tooltip{
	text-transform: capitalize;
	border: none;
	padding: 0 1px;
	color: #f9f9f9;
	background: #050505;
	height: 25px;
	font-size: 15px;
}
.bookmark-icon{
	border: none;
	padding: 0 1px;
	color: #f9f9f9;
	background: #3b3b3b;
	height: 25px;
	font-size: 15px;	
}
.bookmark-icon .mdi-bookmark,
.marketTeamName .bookmark-icon .mdi-bookmark{
	/*color: #fec726;*/
}
.bookmark-icon .mdi-bookmark-outline{
	color: #f9f9f9;
}
.marketTeamName .bookmark-icon{
	background: none;
}
.marketTeamName .bookmark-icon .mdi-bookmark-outline {
    color: #282828;
}
.marketTeamName{
	margin: 0;
	font-size: 12px;
	text-transform: capitalize;
	line-height: normal;
	padding-left: 5px;
}
.marketTeamName span,
.market-name span{
	display: block;
}
.market-name{
	font-size: 12px;
	margin: 0;
	left: 35px;
	position: relative;
	line-height: normal;
	color: #000;
}
.marketTeamName .info-tooltip{
    background: #2828281f;
    color: #3b3b3b;
    height: 19px;
    width: auto;
    padding: 0;
    border-radius: 10px;
}
.dOddsBoxes{
	position: relative;
}
.suspendedLock {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #eeeeeea1;
    color: red;
}
.suspendedLock span{
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
}
.suspendedLock span,
.suspendedLock:hover i{
	visibility: hidden;
	opacity: 0;
	display: initial;
	width: 0;
}
.suspendedLock:hover span{
	opacity: 1;
	width: auto;
	visibility: visible;
}
.dOddsBoxes .btn-group{
	width: 100%;
}
.dOddsBoxes .btn-group button{
    width: 16.6666667%;
    border: none;
    padding: 0;
    height: 34px;
    border-radius: 0;
    line-height: normal;
    font-size: 12px;
    font-weight: 600;
}
.back{
	background: #a6d8ff !important;
}
.back1{
	background: #b4ddfd !important;
}
.back2{
	background: #c3e5ff !important;
}
.lay{
	background: #fac9d4 !important;
}
.lay1{
	background: #f9cdd7 !important;
}
.lay2{
	background: #f9d4dd !important;
}
.table .back{
	background: #a6d8ff !important;
}
.table .lay{
	background: #fac9d4 !important;
}
.odds_body .dOddsBoxes .btn-group .back {
/*    border: 1px solid #94cff7de;*/
}
.back2,
.back1 {
    box-shadow: inset -1px 0px 0px 0px #eee;
}
.lay1, .lay2 {
    box-shadow: inset 1px 0px 0px 0px #eee;
}
.odds_body .dOddsBoxes .btn-group .lay {
/*    border: 1px solid #fbacba;*/
}
.back2,
.back1,
.lay2,
.lay1 {
    background: #fff;
}
/*.dOddsBoxes .btn-group .back {
    box-shadow: inset 0 0 0 1px #b8e1fd;
}
.dOddsBoxes .btn-group .lay {
    box-shadow: inset 0 0 0 1px #f99cac;
}*/
.dOddsBoxes .btn-group button small{
	display: block;
	line-height: normal;
	font-weight: 100;
}
.bk_mr{margin-right: 1px;}
.bk_ml{margin-left: 1px;}
.back_text,
.lay_text{
	text-transform: capitalize;
	font-size: 10px;
	height: 25px !important;
	background: #3b3b3b;
	color: #fff;
}
.back small{
	font-size: 10px;
}
.lay small{
	font-size: 10px;
}
.active_odd{
	background: #2ce72c;
}
a[aria-expanded="true"] .mdi-chevron-down::before {
    content: "\f0143" !important;
}
a.collapseDown{
	/*color: #fec726 !important;*/
	margin-left: 5px;
	margin-right: 5px;
}
.secondBoxeOdds .back2,
.secondBoxeOdds .back1,
.secondBoxeOdds .lay2,
.secondBoxeOdds .lay1{
	background: none;
	border:none;
	box-shadow: none;
}
.secondBoxeOdds .dOddsBoxes .btn-group{
	justify-content: flex-end;
	display: flex;
}
.secondBoxeOdds .col-md-10,
.secondBoxeOdds .col-md-2{
}
.secondBoxeOdds .dOddsBoxes .btn-group button{
	width: 50%;
}
.book{
    text-transform: uppercase;
    font-size: 18px;
    /*color: #fec726;*/
    border: none;
    background: none;
}
.detailsTabsDesign ul{
	border-bottom: none;
}
.detailsTabsDesign ul li{}
.detailsTabsDesign ul li a{
	font-size: 12px;
	color: #555;
	margin-bottom: 0 !important;
}
.detailsTabsDesign ul li a.active,
.detailsTabsDesign ul li a:hover{
	background: #f9f9f9 !important;
	border-radius: 0;
	border-bottom: none !important;
/*	box-shadow: inset 0px 2px 0px 0px #fec726;*/
	color: #282828 !important;
}
/*themes css*/


.accordion .horseUi .card-header{
	background: #282828 !important;
}
.accordion .horseUi .card-header h5{
	color: #fff;
}
/*modal*/
.modalCustomDesign h5{
	text-transform: capitalize;
}
.modalCustomDesign .close{
    position: absolute;
    right: 2px;
    top: 2px;
    border: none;
    border-radius: 50px;
    height: 25px;
    width: 25px;
    color: #000;
}
.modalCustomDesign .modal-body{
	position: relative;
	padding: 0;
}
.modalCustomDesign .modal-content{
	border-radius: 0;
}
.modalCustomDesign .nav li{
	margin-bottom: 0 !important;
}
.modalCustomDesign .nav li a{
	font-size: 12px;
	text-transform: capitalize;
	color: #6c757d;
}
.modalCustomDesign .nav li a.active,
.modalCustomDesign .nav li a:hover{
	color: #000;
/*	border-bottom: 3px solid #fec726 !important;*/
}
.modalCustomDesign .modal-title{
    font-weight: 600;
    margin: 17px;
    margin-bottom: 0;
    margin: 0;
    padding: 12px;
}
.modalCustomDesign .modal-header{
	background: #eee;
    padding: 0;
    margin: 0;
}
.table th{
	padding: 4px 12px;
	text-transform: uppercase;
	font-size: 12px;
	background: #282828;
	color: #fff;
}
.table td{
	padding: 4px 12px;
	text-transform: capitalize;
	font-size: 11px;
	color: #111;
}
.table td a{
	font-weight: 800;
	color: #000;
	text-decoration: underline;
}
.modalCustomDesign .modal-footer{
	padding-top: 0;
	padding-bottom: 0;
}
.modalCustomDesign .modal-footer button{
	font-size: 18px;
	border:none;
	box-shadow: none;
	width: 100%;
}
.modalCustomDesign .modal-footer .btn-success{
	background: green;
}
.modalCustomDesign .modal-footer .btn-danger{
	background: red;
}
/*modal*/

/*scoreboard*/
.container-main {
    padding: 0;
    background-image: url('https://cdn.pixabay.com/photo/2014/04/02/10/40/soccer-304171_1280.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: auto;
    margin-left: auto;
    color: #fff;
    height: 80px;
    display: grid;
    background-position: 100%;
    position: relative;
}
.container-main::after {
    content: '';
    background: #0000006b;
    height: 80px;
    width: 100%;
    display: inline-flex;
    position: absolute;
    left: 0;
}
.row-ctm {
	z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
}
.team {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
}
.match_status {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0;
    padding: 4px 0;
}
.team {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
}
.day, .team_name {
    text-transform: capitalize;
}
.team_name {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
}
.curr_inn {
    font-size: 12px;
    font-weight: 600;
}
.day, .status {
    width: 100%;
}
.day {
    display: block;
    font-size: 10px;
}
/*scoreboard*/


/*betlsip*/
.betslipDesigning{
	background: #fff;
	padding: 4px;
	margin:4px;
	border-radius: 10px;
}
.betslipDesigning h2{
	font-size: 12px;
	margin: 0;
	position: relative;
	padding: 0px 0px 3px 0;
	text-align: center;
	font-weight: 600;
}
.betslipDesigning h2 small{
	display: block;
	text-transform: capitalize;
	font-size: 11px;
	font-weight: 500;
}
.betslipDesigning .btn-group{
	justify-content: center;
	display: flex;
}
.betslipDesigning .btn-group .btn{
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 0;
    font-weight: 700;
    border:none;
    border-radius: 0;
    width: 50%;
    margin: 0 !important;
}
.betslipDesigning .btn-group .btn:hover{
	box-shadow: none;
	border: none;
}
.betslipSelect{
	    margin-bottom: 1px;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.betslipSelect h4{
    text-align: left;
    margin: 0;
    font-size: 11px;
    line-height: 2;
    font-weight: 700;
    text-transform: capitalize;
    color: #000;
}
.betslipSelect h4 small{
	float: right;
	right: 4px;
	position: relative;
}
.betslipSelect input::placeholder{
	color: #000;
}
.betslipSelect input,
.betslipSelect input:focus{
	padding:3px 2px;
	font-size: 12px;
	background: #eee;
	box-shadow: none;
	border:1px solid #e5e1e1;
	height: 28px;
	border-radius: 2px;
	text-align: center;
	color: #000;
}
.bslb-profit input::placeholder{
	color: #fff;
}
.bslb-profit{
	margin-top:3px;
}
.bslb-profit input,
.bslb-profit input:focus{
	color: #fff;
	border:none;
	background: #3b3b3b !important;
}
.betslipSelect input[type=number]::-webkit-inner-spin-button, 
.betslipSelect input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
.btn-place{
	/*background: #fec726;*/
	border-bottom-left-radius: 10px !important;
}
.btn-cancel{
    border-bottom-right-radius: 10px !important;
	background: #dfdfdf;
	color: #3b3b3b !important;
}
.btn-cancel i{
	color: red;
}
.btn-place i{
	color: green;
}
.btn-cancel i,
.btn-place i{
	display: none;
	font-weight: 900;
}
.btn-cancel:hover span,
.btn-place:hover span{
	display: none;
}
.btn-cancel:hover i,
.btn-place:hover i{
	display: inline-block;
}
.oneclickStakes{
	/*margin-top: 3px;*/
}
.oneclickStakes a{
	font-size: 12px;
	color: #282828;
	font-weight: 600;
	padding: 2px;
	display: flex;
	justify-content: center;
	text-transform: capitalize;
}
.oneclickStakes a[aria-expanded="true"] .mdi-chevron-double-down::before{
	content: '\f013f';
}
.oneclickStakes .btn-group{
	width: 100%;
	display: inline-table;
}
.oneclickStakes .btn-group button{
    width: 24.1%;
    padding: 0;
    font-size: 12px;
    border: 1px solid #eeeeee;
    box-shadow: none;
    border-radius: 2px;
    background: #eeeeee;
    color: #373737;
    margin: 0 1px !important;
}
.oneclickStakes .btn-group button:hover{
	border-color: #fff;
	color: #000;
}
/*betslip*/
.mobileTv{
	display: none;
}

/*user pages*/
.clientUi{
	position: relative;
}
.clientUi h2{
	text-transform: uppercase;
	font-size: 15px;
	background: #3c3c3c;
	color: #fff;
	padding: 7px;
	margin-top: 2px;
	margin-bottom: 2px;
	font-weight: 600;
}
.clientUi h2 a{
	/*color: #fec726;*/
}
.clientUi .card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
    margin-top: 20vh;
}
.clientUi .user-profile {
/*    background: -webkit-gradient(to right, #fec726, #f5d475);
    background: linear-gradient(to right, #fec726, #f5d475);*/
	align-items: center;
	display: flex;
	justify-content: center;
}
.clientUi .card-block h4{
    border-bottom: 1px solid #e1e1e1;
    text-transform: uppercase;
    font-size: 14px;
    padding-bottom: 4px;
    font-weight: 600;
 }
 .clientUi .card-block h6{
	text-transform: capitalize;
	color: #000;
	font-size: 12px;
 }
 .clientUi .card-block p{
	text-transform: capitalize;
	margin: 0;
	font-size: 13px;
	font-weight: 600;
 }
.betslip{
	padding:40px 30px;
}
.betslip .input-group{
	height: 60px;
	width: 300px;
	margin: 0 auto;
/*	border:1px solid #fec726;*/
}
.betslip .input-group input,
.betslip .input-group input:focus{
	border:none;
	box-shadow: none;
}
.betslip .input-group .input-group-text{
	/*background: #fec726;*/
	color: #fff;
	border-radius: 0;
}
.betslip .btn-group{
	margin-top: 10px;
}
.betslip .btn-group .btn{
	background: none;
	border: 1px solid #ddd;
	position: relative;
}
.betslip .btn-group .btn i{
	position: absolute;
	right: 8px;
}
.clientTable tbody{
	border-top: none !important;
}
.clientTable th{
	padding: 4px 10px;
}
.clientTable td{
	padding: 10px;
}
.clientTable td.profit,
.clientTable td.loss{
	font-weight: 700;
}
.clientTable th{
	background: #282828;
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 600;
	border-bottom: none !important;
	color: #fff;
}
.clientTable tbody tr:nth-child(odd){
	background: #fff;
}
.clientTable tbody tr:nth-child(even){
	background: #fff;
}
.clientTable tbody tr:hover{
	background: #f9f9f9;
}
.clientTable td{
	font-size: 13px;
	text-transform: capitalize;
	color: #3c3c3c;
}
.clientTable td a{
    color: #282828;
    text-decoration: underline;
    font-weight: 600;
}
.filterIcon,
.filterIcon:hover{
	position: fixed;
	bottom: 50px;
	right: 50px;
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	display: inline-block;
	align-items: center;
	justify-content: center;
	background: #ddd;
	color: #000;
	border-radius: 50px;
	/*background: #fec726;*/
	font-size: 20px;
}
.formSearchbar{
	box-shadow: 0px 0px 6px 0px #e3e3e3;
	padding:15px 10px;
	background: #fff;
	margin-top: 4px;
	margin-bottom: 6px;
	border-radius: 2px;
}
.formSearchbar .btn-gs{
	border: none;
	width: 100%;
	text-transform: capitalize;
	/*background: #fec726;*/
}
.formSearchbar .row{
	margin: 0;
}
.formSearchbar .col-md-4,
.formSearchbar .col-md-6{
	padding: 0 2px;
}
.formSearchbar .btn-download{
    border: none;
    text-transform: capitalize;
    background: #3c3c3c;
    color: #fff;
    width: 100%;
}
.formSearchbar input,
.formSearchbar select{
	width: 100%;
	border-radius: 0;
	border: none;
	box-shadow: 0 1px 4px 0 rgb(0 0 0 / 21%);
	text-transform: capitalize;
}
.formSearchbar input:valid:focus,
.formSearchbar select:valid:focus{
	box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%) !important;
}

.clientUi .nav-pills{
	background: #eee;
}
.clientUi .nav-pills a{
	font-size: 13px;
	text-transform: uppercase;
	color: #000;
	border-bottom: 1px solid #fff;
}
.clientUi .nav-pills a.active,
.clientUi .nav-pills a:hover{
	/*background: #fec726;*/
	color: #fff;
}
.rules_content h2:first-child{
	margin-top: 0;
}
.rules_content h2 {
    margin-bottom: 5px !important;
    margin: 0;
    background: #eee;
    padding: 10px 8px;
    color: #000;
    border-bottom: 1px solid #ddd;
    margin-top: 15px;
    font-size: 14px;
    text-transform: uppercase;
}
.rules_content {
    padding:10px;
    background: #eeeeee66;
}
.rules_content p {
    margin-bottom: 4px;
    padding-left: 27px;
    color: #3c3c3c;
    font-size: 13px;
}
.ch-form{
	padding:30px 20px;
}
.ch-form input{
	width: 100%;
	border:none;
}
.ch-form .form-control:valid:focus{
	box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%) !important;
}
.ch-form .btn-submit{
	width: 100%;
	/*background: #fec726;*/
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
}
.formSearchbar .btn-bcancel{
    width: 100%;
    text-transform: capitalize;
    background: #d3d2d2;
    color: #000;
    border: 1px solid #ddd;
}
.ch-form .col-form-label{
	text-align: right;
}
/*user pages*/
.jackpotList {
	margin-bottom: 5px;
    background: #fff;
    border-radius: 3px;
    border:1px solid #fec726;
    padding: 10px 0;
}
.jackpotList:hover {
    /*background: #fec726;*/
}
.jackpotList img {
    width: 90px;
}
.jackpotList h5{
	font-size: 14px;
	text-align: left;
	text-transform: uppercase;
	font-weight: 600;
	line-height: 3;
}
.jackpotList h5 a{
	color: #000;
}
.cc-detail{
	padding: 12px 0;
}
.cc-detail .input-group{
	border:1px solid #282828;
	background: none;
}
.cc-detail .input-group input{
	border:none;
	background: none;
	text-align: center;
	color: #000;
	font-weight: 600;
}
.cc-detail .input-group .input-group-text{
	text-transform: capitalize;
	cursor: pointer;
	border:none;
	background: #282828;
    border-radius: 0;
    color: #fff;
}
.cc-detail .btn-group{
	margin-top: 5px;
	width: 100%;
}
.cc-detail .btn-group button{
	text-transform: uppercase;
	font-size: 13px;
	box-shadow: none;
	border:none;
}
.cc-detail .btn-group button:focus,
.cc-detail .btn-group button:hover{
	box-shadow: none;
	outline: none;
	border:none;
}
.cc-detail .btn-group .btn-book{
	background: #282828;
	color: #fff;
}
.cc-detail .btn-group .btn-rate{
	background: #eee;
	color: #000;
}
.cc-detail .btn-group .btn-plcbet{
	/*background: #fec726;*/
	color: #fff;
}
.pl-section {
	position: relative;
	border:1px solid #fff;
	display: block;
    background: #3c3c3c;
    padding: 8px 15px;
    border-radius: 3px;
}
.pl-section:hover{
	background: #383838;
}
.plUi .col-md-2{
	padding-left: 0;
	padding-right: 0;
}
.pl-section .avatar-md{
	background: #fff;
	color: #000;
	height: 2.2rem;
	width: 2.2rem;
}
.pl-section:hover .avatar-md{
	background: #eee;
}
.pl-section h3{
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    margin-top: 3px;
}
.pl-section .avatar-title{
    font-size: 20px;
    color: #3c3c3c;
}
.pl-section p{
    color: #fff;
    margin: 0 !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    border-bottom: 1px solid #464646;
    margin-bottom: 2px !important;
}
.jack_row{
	align-items: center;
	width: 100%;
	display: inline-flex;
}
.jack_row .jack_row_column:first-child{
	margin-left: 0;
}
.jack_row .jack_row_column:last-child{
	margin-right: 0;
}
.jack_row_th .jack_row_column{
/*	background: #fec726 !important;*/
	height: auto !important;
	box-shadow: none !important;
	border: none !important;
	font-size: 12px !important;
	line-height: 2;
}
.jack_row .jack_row_column{
	cursor: pointer;
	width: 20%;
	text-align: center;
	background: #fff;
	height: 40px;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	box-shadow: inset 0 0 0 1px #a6d8ff;
	margin: 1px;
	text-transform: capitalize;
	color: #000;
	font-weight: 500;
	font-size: 13px;
	border-radius: 3px;
}
.jack_row .jack_row_column .jack-odds{
	border:none;
	background: none;
	width: 100%;
}
.jack_row .jack_row_column .jack-odds:hover {
    background: #7ec2f7;
}
.jack_row .jack_row_column .jack-odds span{
	display: block;
}
.jack-bets {
    position: absolute;
    right: 0;
    border: none;
    /*background: #fec726;*/
    text-transform: uppercase;
    height: 25px;
    font-weight: 700;
}
.matched_unmatched{
	margin: 3px 4px;
}
.accordion-buttons {
    justify-content: end;
    display: flex;
    line-height: normal;
}
.matched_unmatched a {
    color: #fff;
    text-transform: capitalize;
    background: #282828;
    font-size: 12px;
    padding: 2px 10px;
    margin: 0;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}
.matched_unmatched a[aria-expanded="true"] .mdi-plus::before{
	content: '\f0374';
}
.runner-title{
	text-transform: uppercase !important;
	font-weight: 700;
	font-size: 12px !important;
	background: #fff !important;
}
/*themes css for media*/
@media (max-width: 767px){
	.clientUi .nav-pills {
		flex-direction: inherit !important;
		width: 100%;
		max-width: -webkit-max-content;
		max-width: -moz-max-content;
		max-width: max-content;
		overflow-x: overlay;
		-webkit-overflow-scrolling: overlay;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		white-space: nowrap;
		white-space: -moz-nowrap!important;
		white-space: -hp-nowrap;
		white-space: -o-nowrap;
		margin-bottom: 0;
		display: block;
	}
	.clientUi .nav-pills a{
		display: inline-block;
		background: #dfdfdf;
	}
	.clientTable td,
	.clientTable th{
		white-space: nowrap;
	}
	.formSearchbar .btn-gs{
		margin-top: 5px;
	}
	.formSearchbar .btn-download{
		margin-top: 5px;
	}
	.mobileTv{
		display: inline-block;
	}
	.back2,
	.back1,
	.lay2,
	.lay1,
	.desktopTv{
		display: none;
	}
	.dOddsBoxes .btn-group button{
		width: 49.2%;
		height: 52px;
		font-size: 13px;
	}
	.navbar-custom{
		padding: 0;
		height: 60px;
	}
	.content-page{
		margin-top: 96px !important;
		padding: 0;
	}
	span.pro-user-name{
		display:inline;
		font-size: 14px;
	}
	.dropdown-menu.profile-dropdown.show {
	    width: 50%;
	    right: 10px !important;
	    left: inherit !important;
	    padding: 5px;
	}
	.accordion .card-header{
		padding: 8px 5px;
	}
	.left-side-menu {
	    top: 86px;
	    box-shadow: 0 2px 6px -2px rgb(0 0 0 / 16%), 0 2px 6px 0 rgb(0 0 0 / 12%);
	}
	.logo-box {
	    width: 155px!important;
	    padding-right: 0!important;
	}
	.clientUi .card{
		margin: 0;
	}
	body{
		background: #eee;
		padding-bottom: 0;
	}
/*	.topbar-dropdown .melr:nth-child(1){
		margin-bottom: 3px !important;
	}*/
	.topbar-dropdown .melr{
		display: block !important;
		width: 100%;
		text-align: center;
		padding: 2px;
	}
	.topbar-dropdown .melr span{
		width: 100%;
		display: block;
		padding: 8px;
	}
	.navbar-custom .melr{
		display: none;
	}
	.commentary{
		margin-top: 60px;
		margin-left: 0;
		line-height: 3;
		padding: 0;
	}
	.commentary span{
		padding: 0 4px;
		background: #050505;
	}
	.dtui ul{
		border:none;
		background-position:top;
		/*background: #282828;*/
		padding: 7px 0;
		border-radius: 0;
	    width: 100%;
	    max-width: -webkit-max-content;
	    max-width: -moz-max-content;
	    max-width: max-content;
	    margin: 0 auto 3px;
	    overflow-y: hidden;
	    overflow-x: overlay;
	    -webkit-overflow-scrolling: overlay;
	    -ms-overflow-style: -ms-autohiding-scrollbar;
	    white-space: nowrap;
	    white-space: -moz-nowrap!important;
	    white-space: -hp-nowrap;
	    white-space: -o-nowrap;
	    margin-bottom: 0;
	    display: block;
	}
	.dtui ul li {
	    display: inline-block;
	}
	.dtui ul li a {
	    font-size: 14px;
	    padding: 5px 30px;
	    text-transform: uppercase;
	    border:1px solid #fec726 !important;
	    background: #00000052 !important;
	}
	.tableeventlist div{
		border: none;
		align-items: center;
		height: auto;
	}
	.tableeventlist .row div {
	    height: 40px;
	}
	/*.tableeventlist .col-md-1,*/
	.tableeventlist .col-md-2,
	.tableeventlist .col-md-3,
	.tableeventlist .col-md-4{
		padding: 0 1px;
	}
	.tableeventlist .col-md-4.col-5.text-start{
		padding-left: 5px;
	}
	.tableeventlist .row {
	    border: 1px solid #fec726;
	    margin-bottom: 5px;
	}
	.tableeventlist .row:nth-child(odd){background: #fff;}
	.tableeventlist .row:nth-child(even){background: #fff;}
	.tableeventlist .row .col-md-4:last-child {
	    border-right: none !important;
	}
	.tableeventlist .row:first-child div {
/*	    border-top: none !important;*/
	}
	.tableeventlist .row .col-6{
		border-top: 1px solid #ddd !important;
		border-bottom: 1px solid #ddd !important;
	}
	.rightSidebar{
		display: none;
	}
	.accordion .card-header h5{
		font-weight: 700;
		font-size: 14px;
	}
	.custom-accordion .accordion-arrow {
	    font-size: 20px;
	    top: -2px;
	    right: 5px;
	}
	.horseUi .accordion .card-header h5{
		font-weight: 500;
	}
	#sidebar-menu>ul>li>a{
		padding: 10px 15px;
		font-size: 14px;
	}
	.topnav-menu .dropdown-menu a{
		padding: 12px;
		font-size: 15px;
	}
	.eventtitle{
		font-size: 14px;
		padding: 10px 0;
	}
	.tv-icon, .sb-icon{
		font-size: 22px;
	}
	.odds_head .btn-group button {
	    height: 35px !important;
	}
	.info-tooltip,
	.bookmark-icon{
		padding: 0 3px;
		height: 35px;
	}
	.filterIcon,
	.filterIcon:hover{
		right: 30px;
		bottom: 30px;
	}
	.betslip .btn-group {
		display: grid;
	}
	.betslip .btn-group .btn{
		margin-bottom: 6px;
		font-size: 15px;
		color: #000;
		background: #f9f9f9;
	}
	.table th{
		font-size: 15px;
	}
	.table td{
		font-size: 14px;
	}
	.ch-form {
	    padding: 30px 6px;
	}
	.ch-form .col-form-label {
	    font-size: 11px;
	    font-weight: 700;
	}
	.listOddsStyle .btn-group{
		width: 32.8%;
	}
	.tableeventlist p,
	.inplay{
		font-size: 14px;
	}
	.listOddsStyle .btn-group button{
		height: 36px;
	}
	.clientUi .card-block h6{
		margin-top: 0;
	}
	.suspendedLock span{
		font-size: 14px;
	}
	.suspendedLock{
		font-size: 22px;
	}
	.back{
		background: #91c9f5 !important;
	}
	.lay{
		background: #f7bbc9 !important;
	}
	.back small,
	.lay small{
		font-size: 12px;
		font-weight: 500 !important;
	}
}
